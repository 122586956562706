<template>
    <comp-table ref="comp_table" title="司机管理" entity="Driver" :columns="columns" tableApi="WEB://Driver/FindAllByCompany">
        <template #search="evt">
            <Input v-model="evt.search.username" placeholder="输入司机姓名"></Input>
            <Input v-model="evt.search.idNumber" placeholder="输入身份证号"></Input>
            <Input v-model="evt.search.phone" placeholder="输入手机号码"></Input>
        </template>
        <template #operate>
            <Button type="info" @click="$router.push('/driver/form')">新增</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "@/components/comp-table.vue"
import Request from "@/module/request/request"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "姓名",
                    key: "username",
                },
                {
                    title: "手机号码",
                    key: "phone",
                },
                {
                    title: "身份证号",
                    key: "idNumber",
                },
                {
                    title: "是否启用",
                    render: (h, params) => {
                        return params.row.enabled ? "已启用" : "未启用"
                    },
                },
                {
                    title: "操作",
                    width: 200,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    "align-items": "center",
                                },
                            },
                            [
                                h(
                                    "button",
                                    {
                                        class: "ivu-btn ivu-btn-info",
                                        style: {
                                            marginRight: "5px",
                                        },
                                        onClick: () => {
                                            this.$refs.comp_table.detail(params.row.uuid)
                                        },
                                    },
                                    "详情"
                                ),
                                h(
                                    "button",
                                    {
                                        class: "ivu-btn ivu-btn-success",
                                        style: {
                                            marginRight: "5px",
                                        },
                                        onClick: () => {
                                            this.$router.push({
                                                path: "/driver/form",
                                                query: {
                                                    id: params.row.uuid,
                                                },
                                            })
                                        },
                                    },
                                    "编辑"
                                ),
                                h(
                                    "button",
                                    {
                                        class: "ivu-btn ivu-btn-error",
                                        style: {
                                            marginRight: "5px",
                                        },
                                        onClick: () => {
                                            this.$Modal.confirm({
                                                title: "删除",
                                                content: "确定要删除吗？",
                                                onOk: () => {
                                                    Request.delete("WEB://Driver/Delete", {
                                                        uuid: params.row.uuid,
                                                    }).then(() => {
                                                        this.$Message.success("删除成功")
                                                        this.$refs.table.refresh()
                                                    })
                                                },
                                            })
                                        },
                                    },
                                    "删除"
                                ),
                            ]
                        )
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
